import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {Helmet} from 'react-helmet'
import { withPrefix } from 'gatsby'
import ProjectsMainContent from "../templates/projects/projects-page-content-template"
import ProjectsPosts from "../templates/projects/projects-post-template"
import Hero05 from "../templates/home/hero-05-template"
import HeroImgSubPage from "../templates/about-us/about-us-hero-template"

const Projects = () => (

  <>
    <Layout>
      <Helmet>
        <body className="inner-page" data-active-page="page-projects" />
        <script src="/imageGallery/js/mklb.js" type="text/javascript" defer />
        <link rel="stylesheet" href="/imageGallery/css/mklb.css" />
      </Helmet>
      <SEO title="Projects" />
      {/* <HeroImgSubPage /> */}
      <Hero05 />
      <section className="content-container">
        <ProjectsMainContent />
        <div className="image-listing-container">
          <img className="mklbItem" alt="" src="/img/projects/kitchen.jpg" />
          <img className="mklbItem" alt="" src="/img/projects/accessible-home-1.jpg" />
          <img className="mklbItem" alt="" src="/img/projects/lounge-kitchen.jpg" />
          <img className="mklbItem" alt="" src="/img/projects/dove.jpg" />
          <img className="mklbItem" alt="" src="/img/projects/accessible-home.jpg" />
          <img className="mklbItem" alt="" src="/img/projects/dove-1.jpg" />
          <img className="mklbItem" alt="" src="/img/projects/bathroom.jpg" />
          <img className="mklbItem" alt="" src="/img/projects/laundry.jpg" />
          <img className="mklbItem" alt="" src="/img/projects/accessible-home-2.jpg" />
        </div>
        <div className="projects-listing-wrapper">
          <ProjectsPosts />
        </div>
      </section>
    </Layout>
  </>
)

export default Projects
