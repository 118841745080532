import React from "react"
import { StaticQuery, graphql } from "gatsby"
import RemarkFormatter  from "../../components/remark-formatter"

const SingleProject = (props) => {
    const { data } = props
    const { edges: posts } = data.allMarkdownRemark

    return (
        <>

            {posts &&
                posts.map(({ node: post }) => (
                    <>
                        <div className="project-wrapper">
                            <div className="image-wrapper">
                                <img src={post.frontmatter.image} alt="" />
                            </div>
                            <div className="details-wrapper">
                                <h2 className="name">{post.frontmatter.name}</h2>
                                {post.frontmatter.client &&
                                    <>
                                        <h3>Client</h3>
                                        <p className="project-client">{post.frontmatter.client}</p>
                                    </>
                                }
                                {post.frontmatter.scope &&
                                    <div className="project-scope">
                                        <h3>Project Scope</h3>
                                        <RemarkFormatter className="" content={post.frontmatter.scope} />
                                    </div>
                                }
                                {post.frontmatter.dwellings &&
                                    <div className="project-dwellings">
                                        <h3>Number Of Dwellings</h3>
                                        <RemarkFormatter className="" content={post.frontmatter.dwellings} />
                                    </div>                          
                                }
                                {post.frontmatter.details &&
                                    <div className="project-details">
                                        <RemarkFormatter className="" content={post.frontmatter.details} />
                                    </div>    
                                }
                            </div>
                        </div>

                    </>
                ))}

        </>
    )
}

export default () => <StaticQuery
    query={graphql`
        query SingleProjectQuery {
            allMarkdownRemark(
                filter: {frontmatter: 
                    {templateKey: 
                        { eq: "projects-post" }
                    }
                }
                sort: { order: ASC, fields: [frontmatter___listOrder] }
                ) {
                edges {
                node {
                    id
                    frontmatter {
                        name
                        listOrder
                        region
                        image
                        client
                        scope
                        dwellings
                        details
                        templateKey
                    }
                }
                }
            }
        }
   `}
    render={data => (
        <SingleProject data={data} />
    )}
/>